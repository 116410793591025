import axios from 'axios';
import { $addClass, $qs, $removeClass } from 'fxdom/es';
import { sel, noop } from 'fxjs/es';
import { MuiF } from '../../../../../Mui/F/Function/module/MuiF.js';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';
import { MShopAppProductS } from '../../../../App/Product/S/Function/module/MShopAppProductS.js';
import { MShopUtilF } from '../../../../Util/F/Function/module/MShopUtilF.js';
import { MShopStudioEcommerceInfoS } from '../../../EcommerceInfo/S/Function/module/MShopStudioEcommerceInfoS.js';
import { MShopStudioPbProductCreatorMuiF } from '../Mui/module/MShopStudioPbProductCreatorMuiF.js';
import { checkBaseInfo, setEditorAction } from './fn.js';
import { MShopStudioPbProductCreatorF } from './module/MShopStudioPbProductCreatorF.js';

// TODO 여기에 입고상품 액션 추가

export const openModifier = async ({ stores_product_id, store_id }) => {
  const base_info = await checkBaseInfo(store_id);
  if (!base_info) {
    return;
  }

  const is_mobile = MShopUtilF.isMobile();
  if (is_mobile) {
    $addClass('open-editor', $qs('html'));
  }
  const { product_types, store_ecommerce_info, stores_delivery_companies } = base_info;
  return MuiF.openFrame(MShopStudioPbProductCreatorMuiF.frame, async (f, p, [t]) => {
    const { data: stores_product } = await axios.get(
      UtilS.makeApiUrl(`/:lang/@api/stores/:store_id/pb_products/:stores_product_id`, {
        lang: T.lang,
        store_id,
        stores_product_id,
      }),
    );
    f.is_modal = !MShopUtilF.isMobile();
    box.set('product_types', product_types);
    box.set('pb_stores_product', stores_product);
    box.set('im_pb_stores_product', stores_product);
    box.set('pb_product_spo_options', sel('_.product._.spo._.spo_options', stores_product));
    box.set('pb_thumbnail_photos', sel('_.product.thumbnails.value', stores_product));

    t.makeData = () => ({
      cate_lists: MShopAppProductS.rejectDigitalGoods(true)(box.sel('cate_lists')),
      cate_items: box.sel(`cate_lists->(#${stores_product.sp_cate_list_id})->_->cate_items`),
      crew_domain_name: box.sel('crew_domain_name'),
      action: stores_product?.id ? setEditorAction() : null,
      thumbnails: sel('id', stores_product)
        ? sel('_.product.thumbnails.value', stores_product)
        : box.sel('pb_thumbnail_photos'),
      store_url: box.sel('store_url'),
      product_types,
      stores_product,
      store_ecommerce_info,
      stores_delivery_companies,
      goods_type_id: stores_product.goods_type_id,
      is_studio_worker_admin: !!box.sel('is_studio_worker_admin'),
      is_possible_oversea_crew: MShopStudioEcommerceInfoS.isPossibleOverseaByCrewId(box.sel('crew_id')),
    });
    f.closed = () => {
      if (is_mobile) $removeClass('open-editor', $qs('html'));
    };
    f.hiding = async (el, val) => {
      box.set('im_pb_stores_product', null);
      box.set('pb_stores_product', null);
      box.set('pb_product_spo_options', null);
      box.set('pb_thumbnail_files', []);
      box.set('pb_thumbnail_photos', []);
      if (!MShopUtilF.isMobile()) MShopUtilF.bodyFixed$(false);
      if (val) {
        window.location.reload();
      }
    };
    const beforeRendered = t.rendered;
    t.rendered = (tab_el) => {
      beforeRendered();
      MShopStudioPbProductCreatorF.setOrderCount(tab_el).catch(noop);
    };
  });
};
