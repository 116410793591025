import $dataStr from 'fxdom/es/dataStr.js';
import { go, html, ippL, isNil, match, sel, strMap } from 'fxjs/es';
import { CREW_DOMAIN_NAME } from '../../../../../Creator/Crew/S/constant.js';
import { makeProductStoryEditorHtml } from '../../../../../Creator/ProductStoryEditor/S/tmpl.js';
import { GoodsTypeS } from '../../../../../GoodsType/S/Function/module/GoodsTypeS.js';
import { PBDeliveryTrackingConstantS } from '../../../../../PB/DeliveryTracking/S/Constant/module/PBDeliveryTrackingConstantS.js';
import { SpoSpoConstantS } from '../../../../../Spo/Spo/S/Constant/module/SpoSpoConstantS.js';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';
import { UtilTmplS } from '../../../../../Util/S/Tmpl/module/UtilTmplS.js';
import { MShopStudioPreDiscountProductS } from '../../../PreDiscountProduct/S/Function/module/MShopStudioPreDiscountProductS.js';
import { MShopStudioPreDiscountProductTmplS } from '../../../PreDiscountProduct/S/Tmpl/module/MShopStudioPreDiscountProductTmplS.js';
import { MShopStudioPbProductConstantS } from '../Constant/module/MShopStudioPbProductConstantS.js';
import { MShopStudioPbProductS } from '../Function/module/MShopStudioPbProductS.js';
import { MShopStudioPbProductTmplS } from './module/MShopStudioPbProductTmplS.js';
import { GlobalProductInfoTextarea } from './shippingGlobalHtml.js';
/*

product_types
shipping_companies
stores_product
  - product
  - product_type
  < spo_options
    < spo_option_values
  < spo_items
    x spo_item_stocks
  < spo_text_options
  - spo_producing_period
  < spo_shipping_companies

* */

export const selectCateLists = (cate_lists, selected_id, disabled = false) => html`
  <select name="cate_lists" ${disabled ? 'disabled' : ''}>
    <option value="">대 카테고리</option>
    ${strMap(
      (item) => `
        <option value="${item.id}"${item.id == selected_id ? ' selected' : ''}>${item.name}</option>
      `,
      cate_lists,
    )}
  </select>
`;

export const selectCateItems = (cate_items, selected_id, disabled = false) => html`
  <select name="cate_items" ${disabled ? 'disabled' : ''}>
    <option value="">중 카테고리</option>
    ${strMap(
      (item) => `
        <option value="${item.id}"${item.id == selected_id ? ' selected' : ''} data-is-cloth="${
        item.is_cloth
      }">${item.name}</option>
      `,
      cate_items,
    )}
  </select>
`;

export const refundInfoNormalEx = `상품 개봉 및 훼손 없이, 배송 완료 후 7일 이내인 경우에만 환불이 가능합니다.
크리에이터 배송 상품의 경우 교환/환불/취소 신청은 판매자와 구매자간의 1:1 문의하기를 통해 가능합니다.

* 배송비 부담
고객 부담 : 단순변심, 착오 등 고객 사유
크리에이터 부담 : 상품 불량 등 크리에이터 사유`;
export const refundInfoNormalExEn = `Refunds are only available within 7 days of delivery, provided the product is unopened and undamaged.
For items delivered by creators, exchange/refund/cancellation requests can be made through 1:1 inquiry between the seller and the buyer.

* Shipping Costs
Customer's responsibility: For reasons such as change of mind or mistake.
Creator's responsibility: For reasons such as product defects.`;

export const refundInfoNormalExJp = `商品が未開封で損傷がない場合に限り、配送完了後7日以内に返金が可能です。
クリエイター配送商品の場合、交換・返金・キャンセルの申請は販売者と購入者の1対1のお問い合わせを通じて可能です。

* 配送料負担 
顧客負担：単純な心変わり、誤購入など顧客の理由。
クリエイター負担：商品の不良などクリエイターの理由。
`;

export const refundInfoPrintOnDemandEx = `주문제작 상품은 단순 변심, 주문 착오 등 고객 사유에 따른 교환/환불이 어렵습니다.
상품에 이상이 있을 경우, 1:1문의하기를 통해 크리에이터에게 문의 해주세요`;

export const refundInfoPrintOnDemandExEn = `Made to Order products cannot be exchanged or refunded due to customer reasons such as change of mind or ordering mistakes.
If there is an issue with the product, please contact the creator through the 1:1 inquiry.`;

export const refundInfoPrintOnDemandExJp = `Made to Order商品は、単純な心変わりや注文ミスなど、顧客の理由による交換や返金は難しいです。
商品の不具合がある場合は、1対1のお問い合わせを通じてクリエイターにご連絡ください。`;

export const producingPeriodInput = (product_type_id, spo_producing_period) => html`
  <div class="pb-editor-form__control pb-editor-form__producing-period" data-id="${spo_producing_period?.id}">
    <div class="pb-editor-form__label-txt">
      제작 소요 기간
      ${product_type_id != MShopStudioPbProductConstantS.PB_PRODUCT_TYPES.limited
        ? `<span class="pb-editor-form__required">*</span>`
        : ''}
    </div>
    <div class="pb-editor-form__body-area pb-editor-form__body-area--cols2">
      <div class="pb-editor-form__body">
        <input
          type="number"
          name="avg_days"
          class="pb-editor-form__body-input pb-editor-form__body-input--won"
          min="0"
          value="${spo_producing_period ? UtilS.escape(spo_producing_period.avg_days) : ''}"
          step="1"
          placeholder="예) 평균 0"
        />
        <div class="pb-editor-form__body-right">일</div>
      </div>
      <div class="pb-editor-form__body">
        <input
          type="number"
          name="max_days"
          class="pb-editor-form__body-input pb-editor-form__body-input--won"
          min="0"
          value="${spo_producing_period ? UtilS.escape(spo_producing_period.max_days) : ''}"
          step="1"
          placeholder="예) 최대 0"
        />
        <div class="pb-editor-form__body-right">일</div>
      </div>
    </div>
    <p class="pb-editor-form__error-msg">${MShopStudioPbProductS.pb_product_editor_error.required.text}</p>
  </div>
`;

export const totalStockInput = ({ total_spo_item_stock, is_tpl, skus, is_studio_worker_admin }) => {
  return html`
    <div class="pb-editor-form__control pb-editor-form__total-stock">
      <label class="pb-editor-form__label">
        <div class="pb-editor-form__label-txt">
          판매 재고수량
          <span class="tooltip-icon2 tooltip-icon2--question">${UtilTmplS.tooltipIcon2El()}</span>
          <div class="tooltip-container">
            <div class="editor-tooltip-box">
              ${MShopStudioPbProductTmplS.pdTypeTooltipHTML('total_stock')}
            </div>
          </div>
        </div>
        <div class="pb-editor-form__body">
          <input
            type="number"
            name="total_stock"
            data-spo_item_stock_id="${total_spo_item_stock?.id}"
            class="pb-editor-form__body-input pb-editor-form__body-input--won"
            min="0"
            value="${sel('quantity', total_spo_item_stock) || 0}"
            step="1"
            placeholder="숫자만 입력해주세요."
            ${skus?.length && !is_studio_worker_admin ? 'disabled' : ''}
          />
          <div class="pb-editor-form__body-right">개</div>
        </div>
      </label>
      <p class="pb-editor-form__error-msg">${MShopStudioPbProductS.pb_product_editor_error.required.text}</p>
      <p class="pb-editor-form__txt1">옵션별 재고수량을 입력하면 옵션의 재고수량으로 적용됩니다.</p>
    </div>
    <div
      class="pb-editor-form__control pb-editor-form__real-total-stock"
      ${is_tpl ? '' : 'style="display: none !important;"'}
    >
      <label class="pb-editor-form__label">
        <div class="pb-editor-form__label-txt">
          총 재고수량
          <span class="tooltip-icon2 tooltip-icon2--question">${UtilTmplS.tooltipIcon2El()}</span>
          <div class="tooltip-container">
            <div class="editor-tooltip-box">
              ${MShopStudioPbProductTmplS.pdTypeTooltipHTML('real_total_stock')}
            </div>
          </div>
        </div>
        <div class="pb-editor-form__body">
          <input
            type="number"
            name="real_total_stock"
            data-spo_item_stock_id="${total_spo_item_stock?.id}"
            class="pb-editor-form__body-input pb-editor-form__body-input--won"
            min="0"
            value="${sel('real_quantity', total_spo_item_stock) || 0}"
            step="1"
            placeholder="숫자만 입력해주세요."
            ${skus?.length && !is_studio_worker_admin ? 'disabled' : ''}
          />
          <div class="pb-editor-form__body-right">개</div>
        </div>
      </label>
      <p class="pb-editor-form__error-msg">${MShopStudioPbProductS.pb_product_editor_error.required.text}</p>
      <p class="pb-editor-form__txt1">옵션별 재고수량을 입력하면 옵션의 재고수량으로 적용됩니다.</p>
    </div>
  `;
};

export const optionDirectInput = ([idx, option]) => html`
  <div class="pb-editor-form__control pb-editor-form__direct-option" data-idx="${idx}">
    <label class="pb-editor-form__label" for="option_direct_input${idx}">
      <div class="pb-editor-form__label-txt">옵션 직접 입력형</div>
    </label>
    <div
      class="pb-editor-form__body-area pb-editor-form__body-area--op-add-del pb-editor-form__body-area-op-direct"
    >
      <div class="pb-editor-form__body pb-editor-form__body-op-direct">
        <input
          type="text"
          name="option_direct_input${idx}"
          id="option_direct_input${idx}"
          data-id="${UtilS.escape(option?.id)}"
          value="${UtilS.escape(option?.name) || ''}"
          class="pb-editor-form__body-input pb-editor-form__body-input-op-direct pb-editor-form__body-input--text-length"
          placeholder="예) 이니셜 각인 요청사항을 적어주세요"
        />
        <div class="pb-editor-form__body-length pb-editor-form__body-op-direct-length">
          <span class="pb-editor-form__body-length-txt">${UtilS.checkStringLength(option?.name || '')}</span
          >/100
        </div>
      </div>
      <div class="pb-editor-form__body-area-btn-del">
        <button type="button" class="pb-editor-form__btn-op-del pb-editor-form__btn-op-direct-del">x</button>
      </div>
      <div class="pb-editor-form__body-area-btn-add">
        <button type="button" class="pb-editor-form__btn-op-add pb-editor-form__btn-op-direct-add">+</button>
      </div>
    </div>
    <p class="pb-editor-form__error-msg">
      ${MShopStudioPbProductS.pb_product_editor_error.restrict_character.text}
    </p>
  </div>
`;

export const optionControl = ([idx, option]) => {
  return html`
    <div
      class="pb-editor-form__control-area--cols2 pb-editor-form__option-control"
      data-idx="${idx}"
      data-id="${option?.id}"
    >
      <div class="pb-editor-form__control">
        <label class="pb-editor-form__label">
          <div class="pb-editor-form__label-txt">옵션명</div>
          <div class="pb-editor-form__body">
            <input
              type="text"
              name="option_group_name"
              class="pb-editor-form__body-input pb-editor-form__body-option-group-name"
              value="${UtilS.escape(option?.option_group_name)}"
              placeholder="예) 사이즈, 색상"
            />
          </div>
        </label>
        <p class="pb-editor-form__error-msg">
          ${MShopStudioPbProductS.pb_product_editor_error.required.text}
        </p>
      </div>

      <div class="pb-editor-form__control pb-editor-form__option-detail">
        <label class="pb-editor-form__label" for="option_detail_info${idx}">
          <div class="pb-editor-form__label-txt">옵션 상세 정보</div>
        </label>
        <div class="pb-editor-form__body-area pb-editor-form__body-area--op-add-del">
          <div class="pb-editor-form__body">
            <input
              type="text"
              name="option_detail_info${idx}"
              id="option_detail_info${idx}"
              value="${option?.option_detail_info}"
              class="pb-editor-form__body-input pb-editor-form__body-option-detail-info"
              placeholder="예) 블루, 레드, 핑크 (, 로 구분)"
            />
          </div>
          <div class="pb-editor-form__body-area-btn-del">
            <button type="button" class="pb-editor-form__btn-op-del pb-editor-form__btn-op-del--action">
              x
            </button>
          </div>
          <div class="pb-editor-form__body-area-btn-op-add">
            <button type="button" class="pb-editor-form__btn-op-add pb-editor-form__btn-option-add">+</button>
          </div>
        </div>
        <p class="pb-editor-form__error-msg">
          ${MShopStudioPbProductS.pb_product_editor_error.restrict_character.text}
        </p>
      </div>
    </div>
  `;
};

export const spoItemsArea = ({ spo_options, spo_items, stock_type }) => {
  if (!spo_options || !spo_options.length || !spo_items || !spo_items.length) return '';

  return html`
    <div class="pb-editor-form__spo-items-wrapper">
      <div class="pb-editor-form__label-txt">
        옵션 목록
        <span class="pb-editor-form__label-txt-sub"
          >(총
          <span class="pb-editor-form__label-txt-sub2">${spo_items.length || '0'}</span>
          개)
        </span>
      </div>

      <div class="pb-editor-form__op-select-delete">
        <button type="button" class="pb-editor-form__btn-select-delete">선택 삭제</button>
      </div>

      <div class="pb-editor-form__options-table">${optionsTable({ spo_items, stock_type })}</div>
    </div>
  `;
};

export const optionsTable = ({ spo_items, stock_type }) => {
  if (!spo_items || !spo_items.length) return '';

  return html`
    <table>
      <thead>
        <tr>
          <th class="pb-editor-form__options-check">
            <input type="checkbox" name="op_all" class="pb-editor-form__option-check-all" />
          </th>
          ${strMap(
            (gnon) =>
              html` <th class="pb-editor-form__options-name">
                <span class="group_name" data-group_name="${UtilS.escape(UtilS.escaper(gnon.group_name))}"
                  >${UtilS.escape(gnon.group_name)}</span
                >
              </th>`,
            sel('0.group_names_option_names', spo_items),
          )}
          <th class="pb-editor-form__options-price">옵션가</th>
          ${stock_type == SpoSpoConstantS.pSpoStockType.OPTION_STOCK
            ? `
            <th class="pb-editor-form__options-stock-count">판매 재고수량</th>
          `
            : ''}
          <th class="pb-editor-form__options-del">삭제</th>
        </tr>
      </thead>
      <tbody>
        ${strMap(
          (spo_item) => html`
            <tr data-id="${spo_item.id}" data-spo_item_stock_id="${spo_item._?.spo_item_stock?.id || ''}">
              <td class="pb-editor-form__options-check">
                <input type="checkbox" name="op" class="pb-editor-form__option-check" />
              </td>
              ${strMap(
                (gnon) => html`
                  <td class="pb-editor-form__options-name">
                    <input
                      type="text"
                      name="spo_item"
                      class="pb-editor-form__option-name-input"
                      data-prev_value="${UtilS.escape(gnon.option_name)}"
                      value="${UtilS.escape(gnon.option_name)}"
                    />
                  </td>
                `,
                spo_item.group_names_option_names,
              )}
              <td class="pb-editor-form__options-price">
                <input
                  type="text"
                  name="option_price"
                  class="pb-editor-form__option-price-input"
                  value="${UtilS.commify(spo_item.price) || 0}"
                />
              </td>
              ${stock_type == SpoSpoConstantS.pSpoStockType.OPTION_STOCK
                ? html`
                    <td class="pb-editor-form__options-quantity">
                      <input
                        min="0"
                        step="1"
                        type="number"
                        name="option_quantity"
                        class="pb-editor-form__option-quantity-input"
                        data-id="${spo_item._.spo_item_stock.id}"
                        value="${spo_item._.spo_item_stock.quantity || 0}"
                      />
                    </td>
                  `
                : ''}
              <td class="pb-editor-form__options-del">
                <button type="button" class="pb-editor-form__option-btn-del">
                  <img
                    src="//s3.marpple.co/files/u_2308668/2024/8/original/be7f2e35c9392879fb9e6d58314929edc15b15fa1.svg"
                    alt="x"
                  />
                </button>
              </td>
            </tr>
          `,
          spo_items,
        )}
      </tbody>
    </table>
  `;
};

export const makeProfitShareFormItemHtml = (seller_profit_share = 1, disabled) => html`
  <div class="pb-editor-form__control-area--cols2" data-idx="0" data-id="">
    <div class="pb-editor-form__control">
      <label class="pb-editor-form__label">
        <div class="pb-editor-form__label-txt">
          ${T('셀러 수익 비율')}
          <span class="pb-editor-form__required">*</span>
        </div>
        <div class="pb-editor-form__body">
          <input
            min="0"
            max="100"
            step="1"
            type="number"
            name="seller_profit_share"
            class="pb-editor-form__body-input"
            value="${UtilS.escape((seller_profit_share * 100).toFixed(2))}"
            ${UtilS.addAttrIf(disabled, 'disabled')}
          />
        </div>
      </label>
      <p class="pb-editor-form__error-msg">필수 입력 사항입니다.</p>
    </div>

    <div class="pb-editor-form__control">
      <label class="pb-editor-form__label">
        <div class="pb-editor-form__label-txt">${T('기업 수익 비율')}</div>
        <div class="pb-editor-form__body">
          <input
            type="text"
            name="company_profit_share"
            class="pb-editor-form__body-input"
            value="${UtilS.escape((100 - seller_profit_share * 100).toFixed(2))} %"
            disabled
          />
        </div>
      </label>
      <p class="pb-editor-form__error-msg">특수문자를 제외하고 입력해 주세요.</p>
    </div>
  </div>
`;

export const editorHeaderButtons = ({
  has_permission_to_delete,
  has_permission_to_prohibit,
  has_permission_tpl,
  is_prohibited,
  is_solution,
  product_id,
  store_url,
  goods_type_id,
}) => {
  if (!product_id) {
    return html`
      <div class="pb-editor-form__buttons-wrap buttons" is_open="false">
        <button type="button" class="pb-editor-form__btn-temp-save">${T('question::임시저장')}</button>
        <button type="button" class="pb-editor-form__btn-submit pb-editor-form__btn-submit--disabled">
          ${T('question::상품 등록하기')}
        </button>
      </div>
    `;
  }

  return html`
    <div class="pb-editor-form__buttons-wrap buttons" is_open="false">
      <button type="button" class="pb-editor-form__btn-cancel">${T('취소')}</button>
      <button type="button" class="pb-editor-form__btn-submit">${T('완료')}</button>
      <button class="pb-editor-form__btn-more">
        <img
          src="//s3.marpple.co/files/u_1093672/2020/9/original/8498917a4be98ac032b5d267e4e4de0f217c5ef21.png"
        />
      </button>
      <div class="pb-editor-form__more-buttons more-buttons">
        <a href="${UtilS.escape(store_url)}/products/${UtilS.escape(product_id)}" target="_blank"
          >${T('미리 보기')}</a
        >
        ${UtilS.htmlIf2(
          has_permission_to_delete,
          () => html` <button class="pb-editor-form__btn-delete btn-delete">${T('상품 삭제')}</button> `,
        )}
        ${UtilS.htmlIf2(
          GoodsTypeS.isPbg(goods_type_id) && has_permission_tpl,
          () => html` <button class="pb-editor-form__btn-to-tpl btn-to-tpl">${T('입고 완료')}</button> `,
        )}
        ${UtilS.htmlIf2(
          has_permission_to_prohibit,
          () =>
            html`
              <button class="pb-editor-form__btn-prohibit btn-prohibit">
                ${is_prohibited ? T('판매 보류 취소') : T('판매 보류')}
              </button>
              <button class="pb-editor-form__btn-is_solution btn-is_solution">
                ${is_solution ? T('신고 상품 취소') : T('신고 상품')}
              </button>
            `,
        )}
      </div>
    </div>
  `;
};

export const selectedStoresDeliveryCompany = ({
  selected_stores_delivery_company,
  lang,
  stores_delivery_companies,
}) => {
  if (selected_stores_delivery_company) {
    const {
      delivery_company_id,
      shipping_price,
      free_shipping_min_price,
      is_separate_shipping,
      additional_shipping_price,
      take_back_price,
    } = selected_stores_delivery_company;
    return html`
      <div class="studio-stores-delivery-companies__delivery_company_id">
        택배사:
        ${Object.entries(PBDeliveryTrackingConstantS.info).find(([, { id }]) => {
          return id == delivery_company_id;
        })?.[1]?.name}
      </div>
      <div
        class="studio-stores-delivery-companies__shipping_price studio-stores-delivery-companies__margin-left3"
      >
        / 배송비: ${UtilS.commify(shipping_price)}원
      </div>
      <div
        class="studio-stores-delivery-companies__free_shipping_min_price studio-stores-delivery-companies__margin-left3"
      >
        / 무료 배송 조건:
        ${free_shipping_min_price
          ? `${UtilS.commify(free_shipping_min_price)}원`
          : T('ecommerce_info::설정 안 함')}
      </div>
      ${additional_shipping_price > 0
        ? html`<div
            class="studio-stores-delivery-companies__additional_shipping_price studio-stores-delivery-companies__margin-left3"
          >
            / 도서 산간 추가 비용: ${UtilS.commify(additional_shipping_price)}원
          </div>`
        : ''}
      <div
        class="studio-stores-delivery-companies__additional_shipping_price studio-stores-delivery-companies__margin-left3"
      >
        / 반품 배송비: ${UtilS.commify(take_back_price)}원
      </div>
      <div
        class="studio-stores-delivery-companies__is_separate_shipping studio-stores-delivery-companies__margin-left3"
      >
        / 배송지 부과 범위:
        ${is_separate_shipping
          ? T('ecommerce_info::개별 상품')
          : T('ecommerce_info::동일 배송 정책을 가진 모든 상품')}
      </div>
    `;
  } else if (!stores_delivery_companies?.length) {
    const getText = (
      state,
    ) => `현재 등록된 ${state} 배송 정책이 없습니다. <span class="studio-stores-delivery-companies__goto-ecommerce-info">
    ${state} 배송 정책 등록하기</span>`;

    return match(lang)
      .case('en')(() => getText('영문(EN)샵'))
      .case('jp')(() => getText('일본(JP)샵'))
      .else(() => '');
  } else {
    return '';
  }
};

const selectStoreDeliveryCompany = ({
  input_name,
  title,
  selected_stores_delivery_company,
  stores_delivery_companies,
  warning_message,
  lang,
}) => {
  return html`
    <div class="pb-editor-form__control ${lang ? MShopStudioPbProductConstantS.GLOBAL_TOGGLE_CLASSNAME : ''}">
      <div class="pb-editor-form__label-txt">${title} <span class="pb-editor-form__required">*</span></div>
      <div class="pb-editor-form__body">
        <div class="pb-editor-form__select">
          <select
            name="${input_name}"
            data-fx-json="${$dataStr(stores_delivery_companies)}"
            ${stores_delivery_companies?.length ? '' : 'disabled'}
          >
            ${stores_delivery_companies?.length ? '' : html`<option value="">${warning_message}</option>`}
            ${strMap(
              ({ id, is_default, name }) => html`
                <option value="${id}" ${selected_stores_delivery_company.id == id ? ' selected' : ''}>
                  ${is_default ? '기본 배송 정책' : UtilS.escape(name)}
                </option>
              `,
              lang && stores_delivery_companies?.length
                ? [
                    { id: '', is_default: false, name: '배송정책을 선택해주세요.' },
                    ...stores_delivery_companies,
                  ]
                : stores_delivery_companies,
            )}
          </select>
        </div>
      </div>
      <div class="pb-editor-form__txt1 pb-editor-form__selected-stores-delivery-company">
        ${selectedStoresDeliveryCompany({
          selected_stores_delivery_company,
          lang,
          stores_delivery_companies,
        })}
      </div>
      <p class="pb-editor-form__error-msg"></p>
    </div>
  `;
};

const selectGlobalStoreDeliveryCompany = ({
  selected_stores_delivery_company,
  selected_stores_delivery_company_en,
  selected_stores_delivery_company_jp,
  stores_delivery_companies,
  stores_delivery_companies_en,
  stores_delivery_companies_jp,
}) => {
  const i18n = {
    title: ET('mps2::creator_studio::store_delivery_company_title'),
    desc: ET('mps2::creator_studio::store_delivery_company_desc'),
  };

  return html`
    <div class="${MShopStudioPbProductConstantS.GLOBAL_WRAP_CLASSNAME}--store-delivery-company">
      <div class="pb-editor-form__title-wrap">
        <div class="pb-editor-form__title">
          ${i18n.title}
          <span class="tooltip-icon2 tooltip-icon2--question">${UtilTmplS.tooltipIcon2El()}</span>
          <div class="tooltip-container">
            <div class="editor-tooltip-box">
              ${MShopStudioPbProductTmplS.pdTypeTooltipHTML('stores_delivery_companies')}
            </div>
          </div>
        </div>
        <div class="pb-editor-form__txt1">${i18n.desc}</div>
      </div>

      <div class="pb-editor-form__control-wrap">
        ${selectStoreDeliveryCompany({
          input_name: 'stores_delivery_company_id',
          title: '국내(KR)샵 배송 정책',
          stores_delivery_companies,
          selected_stores_delivery_company,
          warning_message: '샵관리 > 크리에이터정보 에서 배송정보 및 국내 배송정책을 등록해주세요.',
        })}
        ${selectStoreDeliveryCompany({
          input_name: 'stores_delivery_company_id_en',
          title: '영문(EN)샵 배송 정책',
          stores_delivery_companies: stores_delivery_companies_en,
          selected_stores_delivery_company: selected_stores_delivery_company_en,
          warning_message: '미등록',
          lang: 'en',
        })}
        ${selectStoreDeliveryCompany({
          input_name: 'stores_delivery_company_id_jp',
          title: '일본(JP)샵 배송 정책',
          stores_delivery_companies: stores_delivery_companies_jp,
          selected_stores_delivery_company: selected_stores_delivery_company_jp,
          warning_message: '미등록',
          lang: 'jp',
        })}
      </div>
    </div>
  `;
};

export const editor = ({
  stores_product,
  cate_lists,
  cate_items,
  product_types,
  store_ecommerce_info,
  crew_domain_name,
  stores_delivery_companies: all_stores_delivery_companies,
  action,
  thumbnails,
  store_url,
  goods_type_id,
  is_possible_oversea_crew,
  is_studio_worker_admin,
}) => {
  const {
    total_spo_item_stock,
    product_story,
    product,
    has_up,
    spo_text_options,
    stores_delivery_companies,
    stores_delivery_companies_en,
    stores_delivery_companies_jp,
    selected_stores_delivery_company,
    selected_stores_delivery_company_en,
    selected_stores_delivery_company_jp,
    spo_options,
    spo_items,
    spo_producing_period,
    show_spo_producing_period,
    spo,
    product_type_id,
  } = MShopStudioPbProductS.getProps({ stores_product, product_types, all_stores_delivery_companies });

  const is_global = is_possible_oversea_crew
    ? sel('_.base_product.is_use_creator_en', product) ||
      sel('_.base_product.is_use_creator_jp', product) ||
      false
    : false;

  return html`
    <div
      class="pb-editor"
      data-is_tpl="${GoodsTypeS.isTpl(goods_type_id)}"
      data-goods_type_id="${goods_type_id}"
      data-is_global="${is_global}"
      data-is_delivery_company_en="${!!stores_delivery_companies_en?.length}"
      data-is_delivery_company_jp="${!!stores_delivery_companies_jp?.length}"
      data-product_id="${product.id}"
      data-stores_product_id="${stores_product.id}"
    >
      <div class="pb-editor__wrapper">
        <div class="pb-editor__left">
          <div class="pb-editor__head">
            <div class="pb-editor__tip">
              <div class="pb-editor__tip-img">
                <img
                  src="//s3.marpple.co/files/u_218933/2023/1/original/c27897e66cc407a1526fe51f88377995a856aa311.svg"
                  alt=""
                />
              </div>
            </div>
            <h2 class="pb-editor__title">
              ${stores_product?.id ? T('question::상품 수정하기') : T('question::상품 등록하기')}
            </h2>
            <p class="pb-editor__desc">
              <span class="pb-editor-form__required">*</span> 표시는 필수로 입력해야 할 항목입니다.
            </p>
          </div>
          <div class="pb-editor__thumbnails-area">
            ${MShopStudioPbProductTmplS.makePbThumbnailEditorHtml(thumbnails)}
          </div>
          <!-- div class="pb-editor__tooltip"></div -->
        </div>
        <div class="pb-editor__right is-wheel-number-blur">
          <div class="pb-editor__form-buttons pb-editor__form-buttons--top">
            <!-- button type="button">상품 일괄 등록</button -->
            <!-- button type="button" class="pb-editor-form__btn-preview">미리보기</button -->
            ${editorHeaderButtons({
              ...action,
              is_prohibited: stores_product?.is_prohibited,
              is_solution: stores_product?.is_solution,
              product_id: product?.id,
              store_url,
              goods_type_id,
            })}
          </div>

          <div class="pb-editor__form-area">
            ${is_studio_worker_admin ? html`<p>${product.id} (직원전용)</p>` : ''}
            <div
              class="pb-editor-form"
              data-stock_type="${spo?.stock_type || SpoSpoConstantS.pSpoStockType.NONE}"
            >
              <div class="pb-editor-form__control pb-editor-form__control-is-public">
                <div class="pb-editor-form__label-txt">
                  상품 공개 여부 <span class="pb-editor-form__required">*</span>
                </div>
                <div class="pb-editor-form__body pb-editor-form__body--radio pb-editor-form__body--is_public">
                  <label
                    class="pb-editor-form__label pb-editor-form__label--inline pb-editor-form__label--lm-minus3 pb-editor-form__radio-label"
                  >
                    <input type="radio" name="public" value="true" ${product?.is_public ? 'checked' : ''} />
                    공개
                  </label>
                  <label
                    class="pb-editor-form__label pb-editor-form__label--inline pb-editor-form__label--lm10 pb-editor-form__radio-label"
                  >
                    <input type="radio" name="public" value="false" ${!product?.is_public ? 'checked' : ''} />
                    비공개
                  </label>
                </div>
              </div>

              <div ${is_possible_oversea_crew ? '' : ` style="display: none"`}>
                ${MShopStudioPbProductTmplS.makeIsGlobalInputRadioHtml(is_global)}
              </div>

              <hr class="pb-editor-form__pd-type-end-hr" />

              ${MShopStudioPbProductTmplS.makeGlobalProductNameInputHtml({
                name: sel('_.product.name', stores_product) || '',
                name_en: sel('_.product.name_en', stores_product) || '',
                name_jp: sel('_.product.name_jp', stores_product) || '',
              })}

              <hr
                class="pb-editor-form__pd-type-end-hr ${MShopStudioPbProductConstantS.GLOBAL_TOGGLE_CLASSNAME}"
              />

              <div class="pb-editor-form__control pb-editor-form__profit">
                <label class="pb-editor-form__label">
                  <div class="pb-editor-form__label-txt">
                    상품 가격 <span class="pb-editor-form__required">*</span>
                  </div>
                  <div class="pb-editor-form__body">
                    <input
                      type="text"
                      name="profit"
                      value="${isNil(product?.profit) ? '' : UtilS.commify(product?.profit)}"
                      class="pb-editor-form__body-input pb-editor-form__body-input--won"
                      placeholder="상품 가격을 입력해 주세요"
                    />
                    <div class="pb-editor-form__body-right">₩</div>
                  </div>
                </label>
                <p class="pb-editor-form__error-msg">
                  ${MShopStudioPbProductS.pb_product_editor_error.only_number.text}
                </p>
              </div>

              ${UtilS.htmlIf2(
                MShopStudioPreDiscountProductS.isPreProductDiscountSupported(),
                () =>
                  html`
                    <div class="pb-editor-form__control pb-editor-form__pre-discount-price">
                      ${MShopStudioPreDiscountProductTmplS.makePreDiscountPriceTmpl({
                        pre_discount_price: product?.pre_discount_product?.pre_discount_price,
                        price: product?.profit,
                      })}
                    </div>
                  `,
              )}
              ${UtilS.htmlIf2(crew_domain_name !== CREW_DOMAIN_NAME.MARPPLE, () =>
                makeProfitShareFormItemHtml(
                  sel('seller_profit_share', stores_product),
                  product?.is_public || has_up,
                ),
              )}

              <div class="pb-editor-form__control pb-editor-form__category">
                <div class="pb-editor-form__label-txt">
                  상품 카테고리 <span class="pb-editor-form__required">*</span>
                </div>
                <div class="pb-editor-form__body-area pb-editor-form__body-area--cols2">
                  <div class="pb-editor-form__body pb-editor-form__body--category">
                    <div class="pb-editor-form__select pb-editor-form__cate-lists">
                      <div class="pb-editor-form__select-area">
                        ${selectCateLists(cate_lists, stores_product?.sp_cate_list_id)}
                      </div>
                    </div>
                  </div>
                  <div class="pb-editor-form__body pb-editor-form__body--category">
                    <div class="pb-editor-form__select pb-editor-form__cate-items">
                      <div class="pb-editor-form__select-area">
                        ${selectCateItems(cate_items, stores_product?.sp_cate_item_id)}
                      </div>
                    </div>
                  </div>
                </div>
                <p class="pb-editor-form__error-msg">
                  ${MShopStudioPbProductS.pb_product_editor_error.only_number.text}
                </p>
              </div>

              <hr />

              <div class="pb-editor-form__control pb-editor-form__pd-type">
                <div class="pb-editor-form__label-txt">
                  상품 타입 <span class="pb-editor-form__required">*</span>
                  <span class="tooltip-icon2 tooltip-icon2--question">${UtilTmplS.tooltipIcon2El()}</span>
                  <div class="tooltip-container">
                    <div class="editor-tooltip-box">
                      ${MShopStudioPbProductTmplS.pdTypeTooltipHTML('pd_type')}
                    </div>
                  </div>
                </div>
                <div class="pb-editor-form__body">
                  <div class="pb-editor-form__select">
                    <select name="product_type">
                      ${strMap(
                        (product_type) => html`
                          <option
                            value="${product_type.id}"
                            ${product_type_id == product_type.id ? ' selected' : ''}
                          >
                            ${UtilS.escape(product_type.name)}
                          </option>
                        `,
                        product_types,
                      )}
                    </select>
                  </div>
                </div>
                <p class="pb-editor-form__error-msg">
                  ${MShopStudioPbProductS.pb_product_editor_error.required.text}
                </p>
              </div>

              ${show_spo_producing_period ? producingPeriodInput(product_type_id, spo_producing_period) : ''}

              <div
                class="pb-editor-form__control pb-editor-form__control-limited ${!stores_product?.product_type_id ||
                stores_product?.product_type_id == MShopStudioPbProductConstantS.PB_PRODUCT_TYPES.always
                  ? 'pb-editor-form__control-limited--hide'
                  : ''}"
              >
                <div class="pb-editor-form__label-txt">
                  한정 판매 기간&amp;수량 설정
                  <span class="pb-editor-form__required">*</span>
                  <span class="tooltip-icon2 tooltip-icon2--question">${UtilTmplS.tooltipIcon2El()}</span>
                  <div class="tooltip-container">
                    <div class="editor-tooltip-box">
                      ${MShopStudioPbProductTmplS.pdTypeTooltipHTML('limited')}
                    </div>
                  </div>
                </div>
                <div class="pb-editor-form__body pb-editor-form__body--check">
                  <label
                    class="pb-editor-form__label pb-editor-form__label--inline pb-editor-form__label--lm-minus3"
                  >
                    <input
                      type="checkbox"
                      name="check_sales_period"
                      value=""
                      ${stores_product?.sell_start_at ? 'checked' : ''}
                    />
                    한정 판매 기간
                  </label>
                  <label
                    class="pb-editor-form__label pb-editor-form__label--inline pb-editor-form__label--lm10"
                  >
                    <input
                      type="checkbox"
                      name="check_quantity"
                      value=""
                      ${stores_product?.quantity ? 'checked' : ''}
                    />
                    한정 판매 수량
                  </label>
                  <label
                    class="pb-editor-form__label pb-editor-form__label--inline pb-editor-form__label--lm10"
                  >
                    <input
                      type="checkbox"
                      name="check_max_purchase_per_user"
                      value=""
                      ${stores_product?.max_purchase_per_user ? 'checked' : ''}
                    />
                    1인당 구매 가능 수량
                  </label>
                </div>
              </div>

              <div
                class="pb-editor-form__control pb-editor-form__control-sales-period${stores_product?.sell_start_at
                  ? ' pb-editor-form__control-sales-period--show'
                  : ''}"
              >
                <label class="pb-editor-form__label">
                  <div class="pb-editor-form__label-txt">한정 판매 기간</div>
                  <div class="pb-editor-form__body">
                    <input
                      name="sell_at"
                      class="pb-editor-form__body-input pb-editor-form__body-input--sell-at"
                    />
                    <input name="sell_start_at" type="hidden" value="" />
                    <input name="sell_end_at" type="hidden" value="" />
                  </div>
                </label>
                <p class="pb-editor-form__error-msg">
                  ${MShopStudioPbProductS.pb_product_editor_error.required.text}
                </p>
              </div>

              <div
                class="pb-editor-form__control pb-editor-form__control-quantity${stores_product?.quantity
                  ? ' pb-editor-form__control-quantity--show'
                  : ''}"
              >
                <label class="pb-editor-form__label">
                  <div class="pb-editor-form__label-txt">한정 판매 수량</div>
                  <div class="pb-editor-form__body">
                    <input
                      type="number"
                      class="pb-editor-form__body-input"
                      name="quantity"
                      min="1"
                      max="10000"
                      step="1"
                      value="${UtilS.escape(stores_product?.quantity) || 100}"
                    />
                  </div>
                </label>
                <p class="pb-editor-form__error-msg">
                  ${MShopStudioPbProductS.pb_product_editor_error.required.text}
                </p>

                <div style="margin-top: 10px;">
                  <label
                    class="pb-editor-form__label pb-editor-form__label--inline pb-editor-form__label--lm-minus3 pb-editor-form__radio-label"
                  >
                    <input
                      type="radio"
                      name="is_quantity_public"
                      value="true"
                      ${stores_product?.is_quantity_public ? ' checked' : ''}
                    />
                    수량 공개
                  </label>
                  <label
                    class="pb-editor-form__label pb-editor-form__label--inline pb-editor-form__label--lm10 pb-editor-form__radio-label"
                  >
                    <input
                      type="radio"
                      name="is_quantity_public"
                      value="false"
                      ${!stores_product?.is_quantity_public ? ' checked' : ''}
                    />
                    수량 비공개
                  </label>
                </div>
              </div>

              <div
                class="pb-editor-form__control pb-editor-form__control-max_purchase_per_user${stores_product?.max_purchase_per_user
                  ? ' pb-editor-form__control-max_purchase_per_user--show'
                  : ''}"
              >
                <label class="pb-editor-form__label">
                  <div class="pb-editor-form__label-txt">1인당 구매 가능 수량</div>
                  <div class="pb-editor-form__body">
                    <input
                      type="number"
                      class="pb-editor-form__body-input"
                      name="max_purchase_per_user"
                      min="1"
                      max="10000"
                      step="1"
                      value="${stores_product?.max_purchase_per_user || 1}"
                    />
                  </div>
                </label>
                <p class="pb-editor-form__error-msg">
                  ${MShopStudioPbProductS.pb_product_editor_error.required.text}
                </p>
              </div>
              <div
                class="pb-editor-form__control"
                style="display: ${stores_product?.order_count > 0 ? 'block' : 'none'};"
              >
                <div class="pb-editor-form__label-txt">판매된 수량</div>
                <div class="pb-editor-form__txt1" data-order_count="${stores_product?.order_count}">
                  ${UtilS.commify(stores_product?.order_count)}
                </div>
              </div>
              <div class="pb-editor-form__control pb-editor-form__control-stock">
                <div class="pb-editor-form__label-txt">
                  재고 관리 <span class="pb-editor-form__required">*</span>
                  <span class="tooltip-icon2 tooltip-icon2--question">${UtilTmplS.tooltipIcon2El()}</span>
                  <div class="tooltip-container">
                    <div class="editor-tooltip-box">
                      ${MShopStudioPbProductTmplS.pdTypeTooltipHTML('stock')}
                    </div>
                  </div>
                </div>

                <label
                  class="pb-editor-form__label pb-editor-form__label--inline pb-editor-form__label--lm-minus3 pb-editor-form__radio-label"
                >
                  <input
                    type="radio"
                    name="stock_type"
                    value="${SpoSpoConstantS.pSpoStockType.NONE}"
                    ${!sel('_.spo.stock_type', product) ||
                    sel('_.spo.stock_type', product) == SpoSpoConstantS.pSpoStockType.NONE
                      ? 'checked'
                      : ''}
                  />
                  무제한 관리
                </label>
                <label
                  class="pb-editor-form__label pb-editor-form__label--inline pb-editor-form__label--lm10 pb-editor-form__radio-label"
                >
                  <input
                    type="radio"
                    name="stock_type"
                    value="${SpoSpoConstantS.pSpoStockType.TOTAL_STOCK}"
                    ${sel('_.spo.stock_type', product) == SpoSpoConstantS.pSpoStockType.TOTAL_STOCK
                      ? 'checked'
                      : ''}
                  />
                  총 수량 관리
                </label>
                <label
                  class="pb-editor-form__label pb-editor-form__label--inline pb-editor-form__label--lm10 pb-editor-form__radio-label"
                >
                  <input
                    type="radio"
                    name="stock_type"
                    value="${SpoSpoConstantS.pSpoStockType.OPTION_STOCK}"
                    ${sel('_.spo.stock_type', product) == SpoSpoConstantS.pSpoStockType.OPTION_STOCK
                      ? 'checked'
                      : ''}
                  />
                  옵션별 수량 관리
                </label>
              </div>

              ${totalStockInput({ total_spo_item_stock })}

              <hr class="pb-editor-form__pd-type-end-hr" />

              <div class="pb-editor-form__control pb-editor-form__control-option">
                <div class="pb-editor-form__label-txt">
                  옵션
                  <span class="tooltip-icon2 tooltip-icon2--question">${UtilTmplS.tooltipIcon2El()}</span>
                  <div class="tooltip-container">
                    <div class="editor-tooltip-box">
                      ${MShopStudioPbProductTmplS.pdTypeTooltipHTML('option')}
                    </div>
                  </div>
                </div>
                <p class="pb-editor-form__txt1">상품 선택 옵션을 생성합니다.</p>
              </div>

              <div class="pb-editor-form__options">
                ${MShopStudioPbProductTmplS.globalOptionControls(spo_options)}
              </div>

              <div class="pb-editor-form__set-option">
                <button type="button" class="pb-editor-form__btn-set-op-list">
                  <span>옵션 목록으로 적용하기</span>
                </button>
              </div>

              <div class="pb-editor-form__options-area">
                ${MShopStudioPbProductTmplS.globalSpoItemsArea({
                  spo_options,
                  spo_items,
                  stock_type: sel('_.spo.stock_type', product),
                })}
              </div>

              <div class="pb-editor-form__op-direct-input-area">
                ${go(spo_text_options, ippL, strMap(MShopStudioPbProductTmplS.globalOptionDirectInput))}
              </div>

              <div class="pb-editor-form__story">
                ${makeProductStoryEditorHtml(product_story, crew_domain_name)}
              </div>

              <hr />

              ${GlobalProductInfoTextarea({
                product_basic_info: stores_product?.product_basic_info,
                product_basic_info_en: stores_product?.product_basic_info_en,
                product_basic_info_jp: stores_product?.product_basic_info_jp,
              })}

              <div
                class="pb-editor-form__control"
                data-is_certification_info="${!!stores_product?.is_kc_cert_checked}"
              >
                <label class="pb-editor-form__label">
                  <div class="pb-editor-form__label-txt">
                    KC 및 기타 인증 <span class="pb-editor-form__required">*</span>
                    <span class="tooltip-icon2 tooltip-icon2--question">${UtilTmplS.tooltipIcon2El()}</span>
                    <div class="tooltip-container">
                      <div class="editor-tooltip-box">
                        ${MShopStudioPbProductTmplS.pdTypeTooltipHTML('certification_info')}
                      </div>
                    </div>
                  </div>
                  <span
                    class="pb-editor-form__kc-radio ${MShopStudioPbProductConstantS.GLOBAL_WRAP_CLASSNAME}"
                  >
                    <label class="pb-editor-form__radio-label">
                      <input
                        type="radio"
                        name="kc-certification"
                        value="show"
                        ${stores_product?.is_kc_cert_checked ? 'checked' : ''}
                      />
                      KC 인증 있음
                    </label>
                    <label class="pb-editor-form__label--lm10 pb-editor-form__radio-label">
                      <input
                        type="radio"
                        name="kc-certification"
                        value="hide"
                        ${!stores_product?.is_kc_cert_checked ? 'checked' : ''}
                      />
                      해당 없음
                    </label>
                  </span>
                  <p class="pb-editor-form__txt1">
                    품목에 따른 KC 및 기타 인증 정보를 필수로 표기해주세요. 판매 과정에서 판매자가 현행법에
                    저촉하는 일이 없도록 KC 인증 및 별도 허가증 등 정보를 기재하여야 합니다.
                  </p>
                  <div class="${MShopStudioPbProductConstantS.GLOBAL_WRAP_CLASSNAME}--certification_info">
                    <div>
                      ${MShopStudioPbProductTmplS.makeKrTextHtml()}
                      <div
                        class="pb-editor-form__body pb-editor-form__kc-body${stores_product?.is_kc_cert_checked
                          ? ` pb-editor-form__kc-body--show`
                          : ''}"
                      >
                        <input
                          class="pb-editor-form__body-input"
                          name="certification_info"
                          value="${stores_product?.certification_info || ''}"
                          placeholder="인증기준 / 인증기관 / 인증번호 등 (미입력시 KC 마크만 노출됩니다)"
                        />
                      </div>
                    </div>

                    <div class="${MShopStudioPbProductConstantS.GLOBAL_TOGGLE_CLASSNAME}">
                      ${MShopStudioPbProductTmplS.makeEnTextHtml()}
                      <div
                        class="pb-editor-form__body pb-editor-form__kc-body ${MShopStudioPbProductConstantS.GLOBAL_TOGGLE_CLASSNAME}${stores_product?.is_kc_cert_checked
                          ? ` pb-editor-form__kc-body--show`
                          : ''}"
                      >
                        <input
                          class="pb-editor-form__body-input"
                          name="certification_info_en"
                          value="${stores_product?.certification_info_en || ''}"
                          placeholder="인증기준 / 인증기관 / 인증번호 등 (미입력시 KC 마크만 노출됩니다)"
                        />
                      </div>
                    </div>

                    <div class="${MShopStudioPbProductConstantS.GLOBAL_TOGGLE_CLASSNAME}">
                      ${MShopStudioPbProductTmplS.makeJpTextHtml()}
                      <div
                        class="pb-editor-form__body pb-editor-form__kc-body ${MShopStudioPbProductConstantS.GLOBAL_TOGGLE_CLASSNAME}${stores_product?.is_kc_cert_checked
                          ? ` pb-editor-form__kc-body--show`
                          : ''}"
                      >
                        <input
                          class="pb-editor-form__body-input"
                          name="certification_info_jp"
                          value="${stores_product?.certification_info_jp || ''}"
                          placeholder="인증기준 / 인증기관 / 인증번호 등 (미입력시 KC 마크만 노출됩니다)"
                        />
                      </div>
                    </div>
                  </div>
                </label>
                <p class="pb-editor-form__error-msg">숫자를 입력해 주세요.</p>
              </div>

              <div class="pb-editor-form__control">
                <div class="pb-editor-form__label-txt">
                  일대일 문의 방법 설정 <span class="pb-editor-form__required">*</span>
                  <span class="tooltip-icon2 tooltip-icon2--question">${UtilTmplS.tooltipIcon2El()}</span>
                  <div class="tooltip-container">
                    <div class="editor-tooltip-box">
                      ${MShopStudioPbProductTmplS.pdTypeTooltipHTML('qna')}
                    </div>
                  </div>
                </div>

                <div
                  class="pb-editor-form__body pb-editor-form__body--radio pb-editor-form__body--min-height-none"
                >
                  <label
                    class="pb-editor-form__label pb-editor-form__label--inline pb-editor-form__label--lm-minus3 pb-editor-form__radio-label"
                  >
                    <input
                      type="radio"
                      name="qna_type"
                      value="${MShopStudioPbProductConstantS.QNA_TYPES.BOARD}"
                      ${(stores_product?.qna_type || store_ecommerce_info?.qna_type) !=
                      MShopStudioPbProductConstantS.QNA_TYPES.SNS
                        ? 'checked'
                        : ''}
                    />
                    마플샵 일대일 문의
                  </label>
                  <label
                    class="pb-editor-form__label pb-editor-form__label--inline pb-editor-form__label--lm10 pb-editor-form__radio-label"
                  >
                    <input
                      type="radio"
                      name="qna_type"
                      value="${MShopStudioPbProductConstantS.QNA_TYPES.SNS}"
                      ${(stores_product?.qna_type || store_ecommerce_info?.qna_type) ==
                      MShopStudioPbProductConstantS.QNA_TYPES.SNS
                        ? ' checked'
                        : ''}
                    />
                    개인 SNS를 통한 문의
                  </label>
                </div>

                <div
                  class="pb-editor-form__qna-type-mp ${(stores_product?.qna_type ||
                    store_ecommerce_info.qna_type) == MShopStudioPbProductConstantS.QNA_TYPES.SNS
                    ? 'pb-editor-form__qna-type-mp--hide'
                    : ''}"
                >
                  <p class="pb-editor-form__txt1">
                    마플샵 스튜디오 > 고객 문의 관리를 통해 구매자와 메시지를 주고 받을 수 있습니다.
                  </p>

                  <div class="pb-editor-form__inquiry-precautions">
                    <p class="pb-editor-form__inquiry-precautions__txt1">
                      <span class="pb-editor-form__icon-important pb-editor-form__icon-important--ml0"></span>
                      마플샵 일대일 문의 안내
                    </p>
                    <ul>
                      <li>
                        - 마플샵 스튜디오 > 고객 문의 관리를 통해 구매자와 메시지를 주고 받을 수 있습니다.
                      </li>
                      <li>- 구매자의 문의를 7일 이상 미 답변시 7일 동안 판매중지 상태가 될 수 있습니다.</li>
                    </ul>
                  </div>
                </div>

                <div
                  class="pb-editor-form__qna-type-sns ${(stores_product?.qna_type ||
                    store_ecommerce_info?.qna_type) == MShopStudioPbProductConstantS.QNA_TYPES.SNS
                    ? ''
                    : 'pb-editor-form__qna-type-sns--hide'}"
                >
                  <div class="pb-editor-form__control">
                    <label class="pb-editor-form__label">
                      <div class="pb-editor-form__body">
                        <input
                          type="text"
                          name="qna_link"
                          class="pb-editor-form__body-input"
                          value="${stores_product?.qna_link || store_ecommerce_info?.qna_link || ''}"
                          placeholder="예) https://www.instagram.com/marpple.shop/"
                        />
                      </div>
                    </label>
                    <p class="pb-editor-form__error-msg">
                      ${MShopStudioPbProductS.pb_product_editor_error.required.text}
                    </p>
                  </div>
                  <p class="pb-editor-form__txt1">크리에이터의 개인 SNS를 통해 메세지를 주고 받습니다.</p>
                </div>
              </div>

              <hr />
              ${selectGlobalStoreDeliveryCompany({
                stores_delivery_companies,
                stores_delivery_companies_en,
                stores_delivery_companies_jp,
                selected_stores_delivery_company,
                selected_stores_delivery_company_en,
                selected_stores_delivery_company_jp,
              })}
              <div
                class="pb-editor-form__control pb-editor-form__control--refund-info"
                refund_info_type="${stores_product?.refund_info_type ||
                MShopStudioPbProductConstantS.REFUND_INFO_TYPES.normal}"
              >
                <div class="pb-editor-form__label-txt">
                  교환/환불 안내 <span class="pb-editor-form__required">*</span>
                  <span class="tooltip-icon2 tooltip-icon2--question">${UtilTmplS.tooltipIcon2El()}</span>
                  <div class="tooltip-container">
                    <div class="editor-tooltip-box">
                      ${MShopStudioPbProductTmplS.pdTypeTooltipHTML('refund_info')}
                    </div>
                  </div>
                </div>

                <div
                  class="pb-editor-form__body pb-editor-form__body--radio pb-editor-form__body--min-height-none"
                >
                  <label
                    class="pb-editor-form__label pb-editor-form__label--inline pb-editor-form__label--lm-minus3 pb-editor-form__radio-label"
                  >
                    <input
                      type="radio"
                      name="refund_info_type"
                      value="${MShopStudioPbProductConstantS.REFUND_INFO_TYPES.normal}"
                      ${stores_product?.refund_info_type !=
                      MShopStudioPbProductConstantS.REFUND_INFO_TYPES.print_on_demand
                        ? ' checked'
                        : ''}
                    />
                    일반 상품 (교환 가능)
                  </label>
                  <label
                    class="pb-editor-form__label pb-editor-form__label--inline pb-editor-form__label--lm10 pb-editor-form__radio-label"
                  >
                    <input
                      type="radio"
                      name="refund_info_type"
                      value="${MShopStudioPbProductConstantS.REFUND_INFO_TYPES.print_on_demand}"
                      ${stores_product?.refund_info_type ==
                      MShopStudioPbProductConstantS.REFUND_INFO_TYPES.print_on_demand
                        ? ' checked'
                        : ''}
                    />
                    주문 제작 상품 (조건 확인)
                  </label>
                </div>

                <div class="pb-editor-form__refund-info-normal">
                  <p class="pb-editor-form__txt1">
                    단순 변심, 주문 착오 등 고객 사유 반품이 가능한 일반 상품일 때 선택합니다. 내용 추가, 수정
                    가능합니다.
                  </p>
                </div>
                <div class="pb-editor-form__refund-info-print-on-demand">
                  <p class="pb-editor-form__txt1">
                    단순 변심, 주문 착오 등 고객 사유 반품이 불가한 주문 제작 상품일 때 선택합니다. 내용 추가,
                    수정 가능합니다.
                  </p>
                </div>
                <div class="pb-editor-form__control ${MShopStudioPbProductConstantS.GLOBAL_WRAP_CLASSNAME}">
                  <div class="${MShopStudioPbProductConstantS.GLOBAL_WRAP_CLASSNAME}--refund-info">
                    ${MShopStudioPbProductTmplS.makeKrTextHtml()}
                    <div class="pb-editor-form__body pb-editor-form__body--textarea">
                      <textarea name="refund_info" class="pb-editor-form__body-textarea" cols="30" rows="10">
${stores_product?.refund_info || MShopStudioPbProductTmplS.refundInfoNormalEx}</textarea
                      >
                    </div>
                  </div>
                  <div class="mb-18"></div>
                  <div
                    class="${MShopStudioPbProductConstantS.GLOBAL_WRAP_CLASSNAME}--refund-info ${MShopStudioPbProductConstantS.GLOBAL_TOGGLE_CLASSNAME}"
                  >
                    ${MShopStudioPbProductTmplS.makeEnTextHtml()}
                    <div class="pb-editor-form__body pb-editor-form__body--textarea">
                      <textarea
                        name="refund_info_en"
                        class="pb-editor-form__body-textarea"
                        cols="30"
                        rows="10"
                      >
${stores_product?.refund_info_en || MShopStudioPbProductTmplS.refundInfoNormalExEn}</textarea
                      >
                    </div>
                  </div>
                  <div class="mb-18"></div>
                  <div
                    class="${MShopStudioPbProductConstantS.GLOBAL_WRAP_CLASSNAME}--refund-info ${MShopStudioPbProductConstantS.GLOBAL_TOGGLE_CLASSNAME}"
                  >
                    ${MShopStudioPbProductTmplS.makeJpTextHtml()}
                    <div class="pb-editor-form__body pb-editor-form__body--textarea">
                      <textarea
                        name="refund_info_jp"
                        class="pb-editor-form__body-textarea"
                        cols="30"
                        rows="10"
                      >
${stores_product?.refund_info_jp || MShopStudioPbProductTmplS.refundInfoNormalExJp}</textarea
                      >
                    </div>
                  </div>
                  <p class="pb-editor-form__error-msg">
                    ${MShopStudioPbProductS.pb_product_editor_error.required.text}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="pb-editor__form-buttons">
            ${stores_product?.id
              ? html`<button type="button" class="pb-editor-form__btn-cancel">${T('취소')}</button>`
              : html`<button type="button" class="pb-editor-form__btn-temp-save">
                  ${T('question::임시저장')}
                </button>`}
            <button
              type="button"
              class="pb-editor-form__btn-submit${stores_product?.id
                ? ''
                : ' pb-editor-form__btn-submit--disabled'}"
            >
              ${stores_product?.id ? T('완료') : T('상품 등록하기')}
            </button>
          </div>
        </div>
      </div>
    </div>
  `;
};
