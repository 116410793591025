import axios from 'axios';
import { $closest, $find, $setHTML, $show } from 'fxdom/es';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';

export const setOrderCount = async ($tab_el) => {
  const { stores_product_id } = $find('.pb-editor', $tab_el).dataset;
  const { data: order_count } = await axios.get(
    UtilS.makeApiUrl(`/:lang/@api/stores/:store_id/pb_products/:stores_product_id/order_count`, {
      lang: T.lang,
      stores_product_id,
      store_id: box.sel('store_id'),
    }),
  );
  if (order_count) {
    const order_count$ = $find('[data-order_count]', $tab_el);
    order_count$.dataset.order_count = order_count;
    $setHTML(UtilS.commify(order_count), order_count$);
    $show($closest('.pb-editor-form__control', order_count$));
  }
};
